// 侧边菜单滑动
$(function () {
  var $drawer = $("#drawer");
  var $handle = $("#drawerShow");
  var $active = $drawer.find(".ht-drawer__item.active");
  var $subdown = $active.find(".ht-drawer__down");
  var swiper = new Swiper("#drawerRoll", {
    direction: "vertical",
    setWrapperSize: true,
    slidesPerView: "auto",
    freeMode: true,
    speed: 300,
  });

  $handle.on("click", function () {
    $handle.toggleClass("open");
    $drawer.toggleClass("active");
  });

  $drawer.on("click", ".close", function () {
    $handle.removeClass("open");
    $drawer.removeClass("active");
  });

  if ($subdown.length > 0) {
    const height = $subdown.find(".ht-drawer__down-list").outerHeight();
    $active.addClass("fold");
    $subdown.height(height);
  }

  $drawer.on("click", ".tap", function (event) {
    var $link = $(this);
    var $item = $link.parent();
    var $subm = $item.find(".ht-drawer__down");

    if ($subm.length > 0) {
      var $subl = $item.find(".ht-drawer__down-list");
      var height = $subl.outerHeight();
      event.preventDefault();
      $item.toggleClass("fold").siblings("li").removeClass("fold");
      $item.hasClass("fold") ? $subm.height(height) : $subm.height(0);
      $item.siblings("li").find(".ht-drawer__down").height(0);
      setTimeout(function () {
        swiper.update(true);
      }, 400);
    }
  });
});

// 打开弹窗
$(function name(params) {
  $(".showModal").bind("click", function () {
    $("#modalView").show();
    setTimeout(function () {
      $("#modalView").addClass("active");
    }, 0);
  });

  $("#modalView").on("click", ".ht-modal__cancel", function () {
    $("#modalView").removeClass("active");
    setTimeout(function () {
      $("#modalView").hide();
    }, 400);
  });
  $("#modalView").on("click", ".ht-modal__mask", function () {
    $("#modalView").removeClass("active");
    setTimeout(function () {
      $("#modalView").hide();
    }, 400);
  });
});

$.fn.countTo = function (a) {
  a = a || {};
  return $(this).each(function () {
    var c = $.extend(
      {},
      $.fn.countTo.defaults,
      {
        from: $(this).data("from"),
        to: $(this).data("to"),
        speed: $(this).data("speed"),
        refreshInterval: $(this).data("refresh-interval"),
        decimals: $(this).data("decimals"),
      },
      a
    );
    var h = Math.ceil(c.speed / c.refreshInterval),
      i = (c.to - c.from) / h;
    var j = this,
      f = $(this),
      e = 0,
      g = c.from,
      d = f.data("countTo") || {};
    f.data("countTo", d);
    if (d.interval) {
      clearInterval(d.interval);
    }
    d.interval = setInterval(k, c.refreshInterval);
    b(g);
    function k() {
      g += i;
      e++;
      b(g);
      if (typeof c.onUpdate == "function") {
        c.onUpdate.call(j, g);
      }
      if (e >= h) {
        f.removeData("countTo");
        clearInterval(d.interval);
        g = c.to;
        if (typeof c.onComplete == "function") {
          c.onComplete.call(j, g);
        }
      }
    }
    function b(m) {
      var l = c.formatter.call(j, m, c);
      f.html(l);
    }
  });
};

$.fn.countTo.defaults = {
  from: 0,
  to: 0,
  speed: 1000,
  refreshInterval: 100,
  decimals: 0,
  formatter: function (b, a) {
    return b.toFixed(0);
  },
  onUpdate: null,
  onComplete: null,
};
