$(function () {
  var pickerValue = "";
  var $picker = $(".ht-picker");
  $picker.on("click", ".ht-picker__name", function () {
    if ($picker.hasClass("active")) {
      $picker.removeClass("active");
    } else {
      $(".ht-picker__view").show();
      setTimeout(function () {
        $picker.addClass("active");
      }, 0);
    }
  });

  $picker.on("click", ".ht-picker__mask", function (event) {
    event.preventDefault();
    $picker.removeClass("active");
  });

  $picker.on("click", ".ht-picker__cancel", function () {
    event.preventDefault();
    $picker.removeClass("active");
  });

  $picker.on("click", ".ht-picker__ensure", function () {
    event.preventDefault();
    $picker.removeClass("active");
    $(".ht-picker__value").val(pickerValue);
  });

  $picker.on("click", ".ht-picker__item", function () {
    event.preventDefault();
    $(this).addClass("active").siblings().removeClass("active");
    pickerValue = $(this).text();
  });

  $(".ht-picker__pane").bind("transitionend", function () {
    if ($picker.hasClass("active")) return;
    $(".ht-picker__view").hide();
  });

  $(".ht-datepicker").bind("click", function () {
    const $input = $(this).find("input");
    var dateMulti = new dateMultiFunc({
      type: 0,
      cancelFunc: function () {},
      confirmFunc: function (res) {
        const valid = res[0].time;
        $input.val(valid.replace(/\./g, "-"));
        dateMulti.destroy();
      },
    });
    dateMulti.show();
  });
});

$(function () {
  $("#backLast").bind("click", function () {
    window.history.back();
  });
});
